/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import config from '../config';
import globalConst from '../constants';
import {
  availabilityGigsFormatted,
  availabilityTimesOffFormatted,
  hashEmail,
} from '../utils/utils';

//
// GLOBAL selectors
// -----------------------------------------------------------------------------
export const getIsMaintenance = (state) => state.auth.isMaintenance;

//
// Auth selectors
// ------------------------------------------------------------------------------
export const getUserType = (state) => state.auth.userType;
export const getUserInfo = (state) => state.auth.userInfo;
export const getExternalTokens = (state) => state.auth.externalTokens;
export const getEvidosToken = (state) => state.auth.evidosToken || {};

//
// Current user selectors
// ------------------------------------------------------------------------------
export const getProfile = (state) => state.auth.current;
const getIsClient = (state) => state.auth.current.user_type === globalConst.CLIENTS_STR;
export const getIsInternalManager = (state) => state.auth.current.manager_type === globalConst.INTERNAL_TYPE;
export const getIsExternal = (state) => state.auth.current.manager_type === globalConst.EXTERNAL_TYPE;
export const getIsBlocked = (state) => hashEmail(state.auth.current.client_email) === globalConst.EXTERNAL_BLOCKED;
export const getIsExternalBlocked = createSelector(
  getIsExternal,
  getIsBlocked,
  (isExternal, isBlocked) => isExternal && isBlocked
);

export const getIsRedcrossClient = (state) => (
  globalConst.REDCROSS_CLIENTS.indexOf(hashEmail(state.auth.current.client_email)) !== -1
);
export const getRedcrossClient = (state) => hashEmail(state.auth.current.client_email) === globalConst.REDCROSS_CLIENT_BLOCKED;
export const getFletcherClient = (state) => (hashEmail(state.auth.current.client_email) === globalConst.AUTO_APPROVE_BLOCKED);
export const getYzorgClient = (state) => (hashEmail(state.auth.current.client_email) === globalConst.FACTUREN_YZORG);

export const getFletcherManagers = createSelector(
  getIsInternalManager,
  getFletcherClient,
  (isInternal, isClientBlocked) => isInternal && isClientBlocked
);
export const getIsYzorgManagers = createSelector(
  getIsInternalManager,
  getYzorgClient,
  (isInternal, isClientBlocked) => isInternal && isClientBlocked
);

const getClientExcellentFlex = (state) => globalConst.EXCELLENT_FLEX_CLIENT.includes(hashEmail(state.auth.current.client_email));
export const getExternalExcellentFlex = createSelector(
  getIsExternal,
  getClientExcellentFlex,
  (isExternal, isBlocked) => isExternal && isBlocked
);

const getIsInternalExcept = (state) => (hashEmail(state.auth.current.email) === globalConst.INTERNAL_BLOCKED_EXCEPT);

export const getIsInternalBlocked = createSelector(
  getIsInternalManager,
  getFletcherClient,
  getIsInternalExcept,
  (isInternal, isClientBlocked, isExcept) => isInternal && isClientBlocked && !isExcept
);
export const getIsInternalBlockedFletcher = (state) => (
  hashEmail(state.auth.current.email) === globalConst.INTERNAL_BLOCKED_FLETCHER
);
export const getIsInternalBlockedSwzzorg = (state) => (
  globalConst.INTERNAL_BLOCKED_SWZZORG.indexOf(hashEmail(state.auth.current.email)) !== -1
);
export const getIsBlockedKeistadZorg = (state) => (
  hashEmail(state.auth.current.email) === globalConst.INTERNAL_BLOCKED_KEISTAD_ZORG
);
export const getIsInternalBlockedKiz = (state) => (
  globalConst.INTERNAL_BLOCKED_KIZ.indexOf(hashEmail(state.auth.current.email)) !== -1
);

export const getIsFlexwerker = (state) => globalConst.FLEXWERKERZ.email === hashEmail(state.auth.current.client_email);
export const getIsFlexwerkerManagers = createSelector(
  getIsInternalManager,
  getIsExternal,
  getIsFlexwerker,
  (isInternal, isExternal, isFlexwerker) => (isInternal || isExternal) && isFlexwerker
);

export const getLogisticForceClient = () => config.isLogisticForce || false;
const getIsExceptionManagers = (state) => globalConst.LOGISTIC_FORCE_MANAGERS_EXCEPTION.indexOf(hashEmail(state.auth.current.email)) !== -1;
export const getIsLogisticForceManager = createSelector(
  getIsInternalManager,
  getLogisticForceClient,
  getIsExceptionManagers,
  (isInternal, isLogisticForce, isException) => isInternal && isLogisticForce && !isException
);

export const getFeatureIsAutoApprove = createSelector(
  getFletcherClient,
  getLogisticForceClient,
  (isBlocked, isLogisticForce) => !isBlocked && !isLogisticForce
);

export const getBrandColor = (state) => state.auth.current.brand_color || '';
export const getBrandTextColor = (state) => config.isLogisticForce ? '#262626' : state.auth.current.text_color;

export const getAgencyInfo = (state) => state.auth.agencyInfo;
export const getAgencyEmailTemplate = (state) => state.auth.agencyInfo.email_template;

export const getPageInfo = (state) => state.info.page;
export const getNetworkStatus = (state) => state.info.networkStatus;

export const getCanCreateOrg = (state) => hashEmail(state.auth.current.client_email) !== globalConst.BARTKRAMER_CLIENT;

//
// Permissions
// ------------------------------------------------------------------------------
const featureIsTimeTracking = (state) => state.auth.current.feature_setting['is_time_tracking?'] || false;
const getDenyTimeTrackingManagers = (state) => globalConst.MANAGERS_WITHOUT_TIME_TRACKING.indexOf(hashEmail(state.auth.current.email)) !== -1;
export const getFeatureIsTimeTracking = createSelector(
  featureIsTimeTracking,
  getDenyTimeTrackingManagers,
  (isTimeTracking, denyTimeTracking) => isTimeTracking && !denyTimeTracking
);

const featureIsOrtSchema = (state) => state.auth.current.feature_setting['is_ort?'] || false;
const featureIsBilling = (state) => state.auth.current.feature_setting['is_billing?'] || false;
const getDenyBillingClientsManagers = (state) => {
  const isInternal = state.auth.current.manager_type === globalConst.INTERNAL_TYPE;
  const clientsList = [
    '998b697285f70afd5df02114b0f6831abf3777d882ee11bf15485c7210af66f2',
  ];
  const getClients = clientsList.indexOf(hashEmail(state.auth.current.client_email)) !== -1;
  const exceptManagersEmails = [
    'b726622829bb8c41b0b6a12239115872c532bbb69658bb2cc990a3ad8e7b84b6',
    '6633f01d25d01e8eda5f8abec54a6e27b319ebc90343fc08f1f6d2e05144bafb',
    '151e31e21f59edddf81053ec7e3967a5ced07a8d6371256a6d9087763a59a527',
    '77173951d9468dc2f92b6a618e962e6bfa2a99398243c9993bb7b86ae1721d3a',
    'a96a210b236a470a626a3c45e58365114eba87162885aca6183a095cef3d8ed4',
    'a43cebfd747a77685cfc6402d746c2a8615675917b6e792f85a8846d2ca2f705',
  ];
  const getException = exceptManagersEmails.indexOf(hashEmail(state.auth.current.email)) !== -1;
  return getClients && isInternal && !getException;
};
const getDenyBillingManagers = (state) => {
  const blockedManagersList = [
    'd91d453941ad13de4cac451fadd32caff977b39424ce2b4fd73690a1fd709981',
    '312ca4020610d560c9c598a5456659fe08be4220e9d87ba6379fe5c2f8af2955',
    'c7819d0a41525bce940c074af31b23c6bdcc9756d70cb1bd883e7eaa7c3011c1',
    'b03a3cb71d75b28500a6b10ee7b8bb8e9e3dc388a7b4a3ce6226a3dd437abf23',
    '40d2ae456b63fef552239d82cc4d44f192b1478d417bf8dde0fbe4efe0d46ed1',
    '1918ec4b270269a8d2d4f341897b6b2efe7c8445a4ae750ccc79929965f2218d',
    'a03795cfa752c3f186d3d31777fd3000ddc002f8381ae2a7d70ae58af1719a16',
    '0b03c892fd32f6cfecc0f345e19ea51bd4551bbdcb204b881f91b71fe2b4931c',
    '57c6bd32c78fa2be068b4d67b91c23b15447ba590d34f38ef0a940d9393d00f0',
    'c194dc7fccd565257ff7828e7359fd9233651b7d9ba1f9175ceaee8cfba602d0',
    '1a069e3003f9afab6a33597d0a7e3b875a69d9e05d4f1ce6a0c3782f4d1c6dae',
  ];
  const checkIsBlockedManager = blockedManagersList.indexOf(hashEmail(state.auth.current.email)) !== -1;
  return checkIsBlockedManager;
};

export const getFeatureIsOrtSchema = createSelector(
  featureIsOrtSchema,
  getDenyBillingClientsManagers,
  getDenyBillingManagers,
  (isORT, denyBillingClientsManagers, denyBillingManagers) => isORT && !denyBillingClientsManagers && !denyBillingManagers
);

export const getFeatureIsBilling = createSelector(
  featureIsBilling,
  getDenyBillingClientsManagers,
  getDenyBillingManagers,
  (isBilling, denyBillingClientsManagers, denyBillingManagers) => isBilling && !denyBillingClientsManagers && !denyBillingManagers
);
export const getFeatureIsMultiBillingInfo = (state) => state.auth.current.feature_setting['is_agency_multi_billing_infos?'] || false;

export const getFeatureIsTimeTrackingSign = (state) => state.auth.current.feature_setting['is_time_tracking_signature?'] || false;
export const getFeatureIsAgencyFee = (state) => state.auth.current.feature_setting['is_agency_fee?'] || false;
export const getFeatureIsImportingShifts = (state) => state.auth.current.feature_setting['is_importing_shifts?'] || false;
export const getFeatureIsBranding = (state) => state.auth.current.feature_setting['is_branding?'] || false;
export const getFeatureIsRecurring = (state) => state.auth.current.feature_setting['is_recurring_shifts?'] || false;
export const getFeatureIsExtendedReport = (state) => state.auth.current.feature_setting['is_extended_report?'] || false;
export const getFeatureIsSimpleOrtSchema = (state) => state.auth.current.feature_setting['is_simple_ort?'] || false;
export const getFeatureIsInternalManagers = (state) => state.auth.current.feature_setting['is_internal_managers?'] || false;
export const getFeatureIsExternalManagers = (state) => state.auth.current.feature_setting['is_external_managers?'] || false;
export const getFeatureIsMessaging = (state) => state.auth.current.feature_setting['is_messaging?'] || false;
export const getFeatureIsCCemail = (state) => state.auth.current.feature_setting['is_invoice_cc_email?'] || false;
export const getFeatureIsManualApprove = (state) => state.auth.current.feature_setting['is_manual_approve_shift?'] || false;
export const getFeatureIsAdvancedFiltering = (state) => state.auth.current.feature_setting['is_advanced_filtering?'] || false;
export const getFeatureIsCustomFields = (state) => state.auth.current.feature_setting['is_custom_fields?'] || false;
export const getFeatureIsCopyShift = (state) => state.auth.current.feature_setting['is_copy_shift?'] || false;
export const getFeatureIsCalculated = (state) => state.auth.current.feature_setting['is_distance_calculated?'] || false;
export const getFeatureIsWorkerCalendarView = (state) => state.auth.current.feature_setting['is_worker_calendar_view?'] || false;
export const getAllowedAgencyFees = (state) => state.auth.current.feature_setting.allowed_agency_fees || 5;
export const getAllowedUnavailabilities = (state) => state.auth.current.feature_setting.allowed_unavailabilities || 5;
export const getFeatureIsAdditionalWorkerStatuses = (state) => state.auth.current
  .feature_setting['is_additional_worker_statuses?'] || false;
export const getFeatureIsFavoriteWorkersList = (state) => state.auth.current
  .feature_setting['is_favorite_workers_list?'] || false;
export const getFeatureIsBlacklistWorkersList = (state) => state.auth.current
  .feature_setting['is_blacklist_workers_list?'] || false;
export const getFeatureIsStoreFilter = (state) => state.auth.current.feature_setting['is_store_filter?'] || false;
export const getFeatureIsGigCategorySettings = (state) => state.auth.current
  .feature_setting['is_gig_category_settings?'] || false;

export const getIsFreemium = (state) => state.auth.current.subscription_plan === globalConst.FREEMIUM_SUBS;
export const getFeatureIsFull = (state) => state.auth.current.freemium_full_feature_active || false;
export const getFeatureIsFullDays = (state) => state.auth.current.days_of_freemium_full_feature || 0;
export const getFeatureIsExpenses = (state) => state.auth.current.feature_setting['is_expenses?'] || false;
export const getFeatureIsReview = (state) => state.auth.current.feature_setting['is_reviewable?'] || false;
export const getFeatureIsPublicProfile = (state) => state.auth.current.feature_setting['is_public_worker_profile?'] || false;
export const getFeatureIsNewDashboard = (state) => state.auth.current.feature_setting['is_new_dashboard?'] || false;
export const getDefaultScheduleType = (state) => state.auth.current.feature_setting?.default_schedule_type || 'auto_approve';
export const getFeatureIsOrgGroup = (state) => state.auth.current.feature_setting['is_organizations_groups?'] || false;
export const getFeatureIsCreditInvoice = (state) => state.auth.current.feature_setting['is_credit_invoice?'] || false;
export const getFeatureIsMultiDays = (state) => state.auth.current.feature_setting['is_multiday_shifts?'] || false;
export const getFeatureIsMultiRoles = (state) => state.auth.current.feature_setting['is_multiple_roles?'] || false;
export const getFeatureIsSummaryInvoice = (state) => state.auth.current.feature_setting['is_summary_invoice?'] || false;
export const getFeatureIsSellingExpenses = (state) => state.auth.current.feature_setting['is_selling_expenses?'] || false;
export const getFeatureIsPlacementEntities = (state) => state.auth.current.feature_setting['is_placement_entities_enabled?'] || false;
export const getFeatureIsPlannerDistance = (state) => state.auth.current.feature_setting['is_planner_distance?'] || false;
export const getFeatureIsManagerConfigurable = (state) => state.auth.current.feature_setting['is_manager_configurable?'] || false;
export const getFeatureIsFeeInvoice = (state) => state.auth.current.feature_setting['is_fee_invoice?'] || false;
export const getFeatureIsOrgAttributesFilter = (state) => state.auth.current.feature_setting['is_org_attributes_gig_filter?'] || false;
export const getFeatureIsWorkerOnboarding = (state) => state.auth.current.feature_setting['is_worker_onboarding?'] || false;
export const getFeatureIsProjects = (state) => state.auth.current.feature_setting['is_projects?'] || false;
export const getFeatureIsExactSolid = (state) => state.auth.current.feature_setting['is_exact_solid?'] || false;
export const getFeatureIsSDB = (state) => state.auth.current.feature_setting['is_sdb?'] || false;
export const getFeatureIsWorkingTimeRegulations = (state) => state.auth.current.feature_setting['is_working_time_regulations?'] || false;
export const getFeatureIsIndividualWarningForWorklog = (state) => state.auth.current.feature_setting['is_individual_warning_for_worklog?'] || false;
export const getDefaultOpenShift = (state) => state.auth.current.feature_setting.default_open_shift || false;
export const getIsFeatureProtectedWorkersDocuments = (state) => state.auth.current.feature_setting['is_protected_workers_documents?'] || false;
export const getIsFeatureAgreements = (state) => state.auth.current.feature_setting['is_agreements_generation?'] || false;
export const getIsSigningDocuments = (state) => state.auth.current.feature_setting['is_signing_documents?'] || false;
export const getDistanceFormat = (state) => state.auth.current.feature_setting.distance_format || 'km_round_2';
export const getIsWorkerExpiring = (state) => state.auth.current.feature_setting['is_worker_expiring?'] || false;

export const getPreparedDefaultScheduleType = createSelector(
  getDefaultScheduleType,
  getFeatureIsManualApprove,
  getIsExternal,
  (defaultType, isManualApprove, isExternal) => {
    const isSetAutoApprove = (defaultType === globalConst.ASSIGN_TYPE && isExternal)
      || (defaultType === globalConst.MANUAL_APPROVE_TYPE && !isManualApprove);
    if (isSetAutoApprove) {
      return globalConst.AUTO_APPROVE_TYPE;
    }

    return defaultType;
  }
);

//
// Manager settings
// ------------------------------------------------------------------------------
export const getManagerCanPublishShift = (state) => state.auth.current.manager_feature_setting.publish_gig || false;
export const getManagerCanUpdatePendingShift = (state) => state.auth.current.manager_feature_setting.update_pending_gig || false;
export const getManagerCanDeletePendingShift = (state) => state.auth.current.manager_feature_setting.delete_pending_gig || false;
export const getManagerCanUpdateActiveShift = (state) => state.auth.current.manager_feature_setting.update_active_gig || false;
export const getManagerCanDeleteActiveShift = (state) => state.auth.current.manager_feature_setting.delete_active_gig || false;
export const getManagerCanCreateRole = (state) => state.auth.current.manager_feature_setting.create_role || false;
export const getManagerCanUpdateRole = (state) => state.auth.current.manager_feature_setting.update_role || false;
export const getManagerCanDeleteRole = (state) => state.auth.current.manager_feature_setting.delete_role || false;
export const getManagerCanCreateCustomAttribute = (state) => state.auth.current.manager_feature_setting.create_custom_attribute || false;
export const getManagerCanUpdateCustomAttribute = (state) => state.auth.current.manager_feature_setting.update_custom_attribute || false;
export const getManagerCanDeleteCustomAttribute = (state) => state.auth.current.manager_feature_setting.delete_custom_attribute || false;

const getAccessBillingInfo = (state) => state.auth.current.manager_feature_setting.access_billing_info || false;
export const getManagerAccessBilling = createSelector(
  getIsClient,
  getAccessBillingInfo,
  (isClient, isBillingInfo) => isClient || isBillingInfo
);
const getAccessTimeTracking = (state) => state.auth.current.manager_feature_setting.access_time_tracking || false;
export const getManagerAccessTimeTracking = createSelector(
  getIsClient,
  getAccessTimeTracking,
  (isClient, isTimeTracking) => isClient || isTimeTracking
);
const getAccessSettings = (state) => state.auth.current.manager_feature_setting.access_settings || false;
export const getManagerAccessSettings = createSelector(
  getIsClient,
  getAccessSettings,
  (isClient, isSettings) => isClient || isSettings
);
const getAccessDeletingActions = (state) => state.auth.current.manager_feature_setting.access_deleting_actions || false;
export const getManagerAccessDeletingActions = createSelector(
  getIsClient,
  getAccessDeletingActions,
  (isClient, isDeletingActions) => isClient || isDeletingActions
);

export const getManagerCanApproveShift = (state) => state.auth.current.can_approve_gig || false;

const getMedicalHuntManagers = (state) => globalConst.INTERNAL_MEDICAL_HUNT.indexOf(hashEmail(state.auth.current.email)) !== -1;
export const getManagerCanAddWorkers = createSelector(
  getMedicalHuntManagers,
  (denyWorkersEntity) => !denyWorkersEntity
);

//
// Timetracker selectors
// ------------------------------------------------------------------------------
export const getTimeTrackerWorkLog = (state) => state.timetracker.entities.worklogs;
export const geTimeTrackerWorkers = (state) => state.timetracker.entities.workers;
export const getTimeTrackerInfo = (state) => state.timetracker.entities.pagination;
export const getTimeTrackerQuery = (state) => state.timetracker.search;

//
// Worker selectors
// ------------------------------------------------------------------------------
export const getImportWorkers = (state) => state.worker.import;
export const getWorkers = (state) => state.worker.workersList;
export const getWorkersListInfo = (state) => state.worker.entity.pagination;
export const getBlockedInfo = (state) => state.worker.blocked.pagination;
export const getInactiveInfo = (state) => state.worker.inactive.pagination;
export const getSourcedInfo = (state) => state.worker.sourced.pagination;
export const getIsPasswordExist = (state) => state.worker.isPasswordExist;

export const getWorkersEntity = (state) => state.worker.entity;
export const getWorkersList = createSelector(
  getWorkersEntity,
  (entity) => entity.allIds.map((itemId) => {
    const item = entity.byId[itemId];
    const rolesList = item.roleIds.map((roleId) => entity.byRoleId[roleId]);
    const attrList = item.attrIds.map((attrId) => entity.byAttrId[attrId]);

    return ({
      ...item,
      roles: rolesList,
      attributes: attrList
    });
  })
);
export const getIsWorkerLoading = (state) => state.worker.entity.loading;

const getBlockedEntity = (state) => state.worker.blocked;
export const getBlockedWorkers = createSelector(
  getBlockedEntity,
  (entity) => entity.allIds.map((itemId) => {
    const item = entity.byId[itemId];
    const rolesList = item.roleIds.map((roleId) => entity.byRoleId[roleId]);
    const attrList = item.attrIds.map((attrId) => entity.byAttrId[attrId]);

    return ({
      ...item,
      roles: rolesList,
      attributes: attrList
    });
  })
);
const getInactiveEntity = (state) => state.worker.inactive;
export const getInactiveWorkers = createSelector(
  getInactiveEntity,
  (entity) => entity.allIds.map((itemId) => {
    const item = entity.byId[itemId];
    const rolesList = item.roleIds.map((roleId) => entity.byRoleId[roleId]);
    const attrList = item.attrIds.map((attrId) => entity.byAttrId[attrId]);

    return ({
      ...item,
      roles: rolesList,
      attributes: attrList
    });
  })
);
const getSourcedEntity = (state) => state.worker.sourced;
export const getSourcedWorkers = createSelector(
  getSourcedEntity,
  (entity) => entity.allIds.map((itemId) => {
    const item = entity.byId[itemId];
    const rolesList = item.roleIds.map((roleId) => entity.byRoleId[roleId]);
    const attrList = item.attrIds.map((attrId) => entity.byAttrId[attrId]);

    return ({
      ...item,
      roles: rolesList,
      attributes: attrList
    });
  })
);

export const getCurrentWorker = (state) => state.worker.entity.current;
export const getCurrentWorkerRoles = (state) => state.worker.entity.current.roles || [];
export const getCurrentWorkerRolesIds = createSelector(
  getCurrentWorkerRoles,
  (roles) => roles.map((item) => item.id),
);

const workerGigSelector = (state) => state.worker.entity.current.gigs || [];
const workerForeignGigSelector = (state) => state.worker.entity.current.foreign_gigs || [];
const workerTimeOffSelector = (state) => state.worker.entity.current.time_offs || [];

export const getAvailability = (state) => {
  const gigs = workerGigSelector(state);
  const foreignGigs = workerForeignGigSelector(state);
  const timesOff = workerTimeOffSelector(state);

  const availabilityGigs = availabilityGigsFormatted(gigs);
  const availabilityForeignGigs = availabilityGigsFormatted(foreignGigs, globalConst.BUSY_FOREIGN_STATUS);
  const availabilityTimesOff = availabilityTimesOffFormatted(timesOff);

  return [...availabilityGigs, ...availabilityForeignGigs, ...availabilityTimesOff];
};

export const getInvitesListInfo = (state) => state.invitee.entity.pagination;
export const getInvitesEntity = (state) => state.invitee.entity;
export const getInvitesList = createSelector(
  getInvitesEntity,
  (entity) => entity.allIds.map((orgId) => entity.byId[orgId])
);

//
// Organization selectors
// ------------------------------------------------------------------------------
export const getOrganizationEntity = (state) => state.organization.entity;
export const getOrganizationsCount = (state) => state.organization.entity.allIds.length;
export const getCurrentOrg = (state) => state.organization.entity.current;
export const getOrganizationIsLoaded = (state) => state.organization.loaded;

export const getClientList = createSelector(
  getOrganizationEntity,
  (entity) => entity.allIds.map((orgId) => entity.byId[orgId]).sort((a, b) => a.title.localeCompare(b.title))
);

export const getActiveOrgs = createSelector(
  getOrganizationEntity,
  (entity) => entity.allIds
    .filter((orgId) => entity.byId[orgId].status === globalConst.ACTIVE_STATUS)
    .map((orgId) => entity.byId[orgId]).sort((a, b) => a.title.localeCompare(b.title))
);

export const getActiveOrgIds = createSelector(
  getOrganizationEntity,
  (entity) => entity.allIds.filter((orgId) => entity.byId[orgId].status === globalConst.ACTIVE_STATUS)
);

export const getOrganizationGroupEntity = (state) => state.organization.groupEntity;
export const getGroupsList = createSelector(
  getOrganizationGroupEntity,
  (entity) => entity.allIds.map((orgId) => entity.byId[orgId]).sort((a, b) => a.title.localeCompare(b.title))
);
export const getOrganizationBillingInfo = (state) => state.organization.billingInfo;

export const getWorkerBillingInfo = (state) => state.worker.billingInfo;
export const getProfileBillingInfo = (state) => state.auth.billingInfo;
export const getAgencyFees = (state) => state.auth.agencyFees;

//
// Roles selectors
// ------------------------------------------------------------------------------
export const getRolesEntity = (state) => state.roles.entity;
export const getRolesLoading = (state) => state.roles.loading;
export const getRolesList = createSelector(
  getRolesEntity,
  (entity) => entity.allIds.map((id) => entity.byId[id])
    .sort((a, b) => a.title.localeCompare(b.title)),
);
export const getFilteredRolesList = createSelector(
  getRolesList,
  getCurrentWorkerRolesIds,
  (roles, workerRoles) => {
    if (isEmpty(getRolesList)) {
      return [];
    }

    return roles.filter((role) => workerRoles.indexOf(role.id) === -1);
  }
);

//
// selectors for settings
// ------------------------------------------------------------------------------
export const getProperties = (state) => state.settings.entity;
export const getPropertiesLoading = (state) => state.settings.loading;
export const getRemovalReasons = (state) => state.settings.removalReasons;

const getListOfExpenses = (state) => state.settings.listExpenses;
export const getListOfEnabledExpenses = createSelector(
  getListOfExpenses,
  (expenses) => {
    const enabledExpenses = expenses.filter((el) => el.attributes.enabled);
    return enabledExpenses;
  }
);

const getPropertiesEntityGigId = (state) => state.settings.entity.gig;
const getPropertiesEntityOrganizationId = (state) => state.settings.entity.organization;
const getPropertiesEntityWorkerId = (state) => state.settings.entity.worker;
const getPropertiesEntityObject = (state) => state.settings.entity.byId;

export const getGigProperties = createSelector(
  getPropertiesEntityGigId,
  getPropertiesEntityObject,
  (gigIds, byId) => {
    const gigAttributes = gigIds.map((itemId) => byId[itemId])
      .sort((a, b) => a.input_type.localeCompare(b.input_type));

    return gigAttributes;
  }
);
export const getOrganizationProperties = createSelector(
  getPropertiesEntityOrganizationId,
  getPropertiesEntityObject,
  (organizationIds, byId) => {
    const gigAttributes = organizationIds.map((itemId) => byId[itemId])
      .sort((a, b) => a.input_type.localeCompare(b.input_type));

    return gigAttributes;
  }
);
export const getWorkerProperties = createSelector(
  getPropertiesEntityWorkerId,
  getPropertiesEntityObject,
  (workerIds, byId) => {
    const gigAttributes = workerIds.map((itemId) => byId[itemId])
      .sort((a, b) => a.input_type.localeCompare(b.input_type));

    return gigAttributes;
  }
);

export const getPropertiesList = createSelector(
  getPropertiesEntityGigId,
  getPropertiesEntityOrganizationId,
  getPropertiesEntityWorkerId,
  getPropertiesEntityObject,
  (gigIds, organizationIds, workerIds, byId) => {
    const gigList = gigIds.map((itemId) => byId[itemId]);
    const organizationList = organizationIds.map((itemId) => byId[itemId]);
    const workerList = workerIds.map((itemId) => byId[itemId]);
    return [
      ...gigList,
      ...organizationList,
      ...workerList,
    ];
  }
);

const getShiftTypesEntity = (state) => state.settings.entityShift;
export const getShiftTypeList = createSelector(
  getShiftTypesEntity,
  (shiftTypesEntity) => {
    const shiftTypeList = shiftTypesEntity.allIds.map((itemId) => shiftTypesEntity.byId[itemId])
      .sort((a, b) => a.title.localeCompare(b.title));

    return shiftTypeList;
  }
);

//
// selectors for agreements
// ------------------------------------------------------------------------------

export const getAgreementsEntity = (state) => state.agreements.entity;
export const getAgreementTemplatesEntity = (state) => state.agreements.templates;
export const getAgreementCustomAttributesEntity = (state) => state.agreements.customAttributes;
export const getAgreementCustomAttributesLoading = (state) => state.agreements.areCustomAttributesLoading;
export const getBillingAgreementsEntity = (state) => state.agreements.billingAgreements;

//
// selectors for filters
// ------------------------------------------------------------------------------
export const getFiltersEntity = (state) => state.filter.entity;
export const getFilterLoading = (state) => state.filter.loading;

const getFilterEntityOrganizationId = (state) => state.filter.entity.organization;
const getFilterEntityInvoiceId = (state) => state.filter.entity.invoice;
const getFilterEntityReportId = (state) => state.filter.entity.report;
const getFilterEntityWorkerId = (state) => state.filter.entity.worker;
const getFilterEntityWorklogId = (state) => state.filter.entity.worklog;
const getFilterEntityGenerateId = (state) => state.filter.entity.generate_invoice;
const getFilterEntityGigId = (state) => state.filter.entity.gig;
const getFilterEntityProjectId = (state) => state.filter.entity.project;
const getFilterEntityAgreements = (state) => state.filter.entity.agreement;
const getFilterEntityObject = (state) => state.filter.entity.byId;

export const getFilterOrganizationList = createSelector(
  getFilterEntityOrganizationId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterAgreementsList = createSelector(
  getFilterEntityAgreements,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterInvoiceList = createSelector(
  getFilterEntityInvoiceId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterReportList = createSelector(
  getFilterEntityReportId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterGenerateList = createSelector(
  getFilterEntityGenerateId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterWorkerList = createSelector(
  getFilterEntityWorkerId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterWorklogList = createSelector(
  getFilterEntityWorklogId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterGigList = createSelector(
  getFilterEntityGigId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

export const getFilterProjectList = createSelector(
  getFilterEntityProjectId,
  getFilterEntityObject,
  (filterIds, byId) => {
    const filterList = filterIds.map((itemId) => byId[itemId]);
    return [
      ...filterList,
    ];
  }
);

//
// selectors for notifications
// ------------------------------------------------------------------------------
export const getNotificationsEntity = (state) => state.notifications.entity;

export const getGreetingPopup = (state) => state.notifications.greetingPopup;

//
// selectors for review
// ------------------------------------------------------------------------------
export const getReviewsEntity = (state) => state.review.entity;
export const getReviewsList = createSelector(
  getReviewsEntity,
  (entity) => entity.allIds.map((itemId) => entity.byId[itemId])
);
export const getReviewInfo = (state) => state.review.entity.pagination;

//
// LogisticForce
// ------------------------------------------------------------------------------
const managersLFWithFilters = [
  { email: '60d24e9c7af4f89924231d6bdc23e97a1490e274e88c4d154b73bf868a2d7c7a', filters: { 129: { title: 'Alkmaar', id: 129 } } },
  { email: '87aaa7837d3037188dc8db3ee883072dab44defec0c27bf48ee60fa2aec69f3b', filters: { 129: { title: 'Alkmaar', id: 129 } } },
  { email: '7cc865b1985ef5354449767ec7ec740970f531eb791eccb9439a40ab32a63c42', filters: { 129: { title: 'Alkmaar', id: 129 } } },
  { email: '8e6ff6308b3e1c1320a3fe4c4db03c3de93f307ee455ecfd22e861a58b1acf05', filters: { 129: { title: 'Alkmaar', id: 129 } } },
  { email: '73c0d5d00fcf48f2680dd5877a87e6a115c6a16b2c3557fae6faec0720b61058', filters: { 129: { title: 'Alkmaar', id: 129 } } },

  { email: '4c5b255ec2843292ae2fb8dd587c1969c9e903fb39773fe4067592e7bc7e1328', filters: { 129: { title: 'Amsterdam', id: 129 } } },

  { email: '57b17f3441daa29742dc9bab289f72f6895222858544daad6c48f3c7e8c3adfd', filters: { 129: { title: 'Apeldoorn', id: 129 } } },
  { email: '2a5091d754d9b2944096ee2b3704cc0af0dfb9228706ea5abbceed14ac8830c4', filters: { 129: { title: 'Apeldoorn', id: 129 } } },
  { email: 'c178524157dc2ec226cecb9f468a58cfd53818ccb777c06b80583ddf53c3b164', filters: { 129: { title: 'Apeldoorn', id: 129 } } },

  { email: '3fc93c88c4abfc55e02ae70ab78a2a26fe2bedb5f30f70f10240c52346739ee6', filters: { 129: { title: 'Breda', id: 129 } } },
  { email: '431dc0b5ea289452f79dafacb6e8e7aae83f5f2e7e9627c4f7e2abe79410b1ae', filters: { 129: { title: 'Breda', id: 129 } } },
  { email: '3011e5e19e98efe93824cd7f5c0911be5e9739146526214945a521d0b2d71d68', filters: { 129: { title: 'Breda', id: 129 } } },
  { email: '0a616a350300740b54cb1bc2077b7f6883266dc3a4ada3724ef4ea6b22ad98df', filters: { 129: { title: 'Breda', id: 129 } } },
  { email: '5e513034d4e2d926676b97c5f2b83c475ce44fa5afda78f94b1313f3b9e52306', filters: { 129: { title: 'Breda', id: 129 } } },
  { email: '6f87743a8d6846f73ee3839bc37f8ed49f8a70a3d0bec2b4a56472b9f67e6e0e', filters: { 129: { title: 'Breda', id: 129 } } },

  { email: '5b8d46ceaf96d2daba744a41256e22b217dc93fd118673848e50dccde80b68c6', filters: { 129: { title: 'Den Bosch', id: 129 } } },
  { email: '6fcc01c6754652df80fabaa7fe3bfd6b62bb23dce1cd258a58775deb342daf47', filters: { 129: { title: 'Den Bosch', id: 129 } } },
  { email: 'a0d5f7579729ba1baefee0adf384cd43409673a532eaa40a25f81d68950b40c5', filters: { 129: { title: 'Den Bosch', id: 129 } } },
  { email: 'e18f982cff0566e260481d2c81663110f980662ec6557f1430c723a678e75677', filters: { 129: { title: 'Den Bosch', id: 129 } } },
  { email: 'ec9803a83b5d884b5ece8eac3bff6d392a658d85d091db5e12be09970237228f', filters: { 129: { title: 'Den Bosch', id: 129 } } },
  { email: 'e589f4665ce2bb394d89080c8968b0c822c82c1489bfc247860989b520d16a5f', filters: { 129: { title: 'Den Bosch', id: 129 } } },

  { email: '12c405159c28b15a96d8d339490ca9baa57b6782899e23a768205a681406ff8a', filters: { 129: { title: 'Deventer', id: 129 } } },
  { email: '25576a9b99c8d159e4c17215ebb49ecaa9e3750ececa9ee0fd91481b5de33cdb', filters: { 129: { title: 'Deventer', id: 129 } } },
  { email: 'c669f1437ada67817b9dba50eedd4110500f95f669cb6bb72881e49dc2a9bf5f', filters: { 129: { title: 'Deventer', id: 129 } } },

  { email: '0be384dc221306497de40699442d99cf478cbd6dc6036717b7d2cf2cd5fe70f1', filters: { 129: { title: 'Eindhoven', id: 129 } } },
  { email: 'a9fde1ea7223f373bb87d406e12e1097fa12c939e6ff6dabacb8d853d1d2790f', filters: { 129: { title: 'Eindhoven', id: 129 } } },
  { email: '9ae8c3b45f403ccc1dde26b64dd21d6f8095b957dbe223d33f879a59a7cb2249', filters: { 129: { title: 'Eindhoven', id: 129 } } },
  { email: '3db1d073a381cccd80632c74ec04f57227b29d954f34805d7a1e2a77e24b0dce', filters: { 129: { title: 'Eindhoven', id: 129 } } },
  { email: 'f5294201b160a7276264ab619ad9d42c8ad92f16a7ed6692cd4e9340c0c68c09', filters: { 129: { title: 'Eindhoven', id: 129 } } },

  { email: '1eed1a81b85e7ec03d736ea6ee9fa53982a6ac93388ab17e158f23baacd565a4', filters: { 129: { title: 'Gorinchem', id: 129 } } },
  { email: '7ccad96f4460fb818ad4ca8d652bd0413bb5e383117c40c8ea9dc18f216debb6', filters: { 129: { title: 'Gorinchem', id: 129 } } },
  { email: 'fa36e233691e01de34bfd7c968369008e3f9f75b7d8a64140eab47f667f44c71', filters: { 129: { title: 'Gorinchem', id: 129 } } },
  { email: '8a90f03f375c281b9e46d86f705db3b24fe750db09a9b39ca15f59d780c30c4f', filters: { 129: { title: 'Gorinchem', id: 129 } } },

  { email: '3e1213e2877121a9a193e9395bc609b6d7a03a0c0a39213e8018d2c9013e0d26', filters: { 129: { title: 'Groningen', id: 129 } } },
  { email: '1e85849a14dc9450df00cc8916c60c049e5a26bb09224199843abc044617bf12', filters: { 129: { title: 'Groningen', id: 129 } } },
  { email: '1e1ae4275da28fd508fe7235020b9cf41bc351274e75ffbb29652a4c811b3d95', filters: { 129: { title: 'Groningen', id: 129 } } },
  { email: '58fbc933d622c7254bbf813ed05d2aeb0c462e9ba4e774d59fbf8b91cf38ecbb', filters: { 129: { title: 'Groningen', id: 129 } } },
  { email: 'a1ff28fc3d86bacd49ad200f42b480eed298bae18e17862495e2e45e87040262', filters: { 129: { title: 'Groningen', id: 129 } } },

  { email: 'e0305fbb82eb7b3e000c23ce6617e741bad263033f910b0595742178976ffbb0', filters: { 129: { title: 'Nieuwegein', id: 129 } } },
  { email: '28608d03dec9eecd8d3a801b446a7f75dc1ec2d9b255697ec7c54e14af8577a7', filters: { 129: { title: 'Nieuwegein', id: 129 } } },

  { email: '7699b458a27c4efd3e0d98d3db791c68d3c8bf4349d7c2770cc8237358c9b62f', filters: { 129: { title: 'Nijmegen', id: 129 } } },
  { email: 'a969140ec5082fcedd31ce1d7414dae92fa390b7466570aa5ff2d006d13c5908', filters: { 129: { title: 'Nijmegen', id: 129 } } },
  { email: '11a1e507a1669c1408bd95073fb56deb3204bd3fefc4343074881c109a0e334c', filters: { 129: { title: 'Nijmegen', id: 129 } } },
  { email: '29699bc6f98a94ea016ca0bdd89fe2d1d56d958e772c6e0f1bbaa6d949cce4ab', filters: { 129: { title: 'Nijmegen', id: 129 } } },

  { email: 'df57630c104ee91b75b4c3baa579efebce22a5a30e61b1de9afe1a0bf4a987f4', filters: { 129: { title: 'Oudehaske', id: 129 } } },
  { email: 'eff27fc46d920addf12f0a83eab4ef7582b8f586b78d62db50d4e87a858eb466', filters: { 129: { title: 'Oudehaske', id: 129 } } },
  { email: '45099c44386e522129c7aa536788a1c4670534ae739fe85d41febccb3399577d', filters: { 129: { title: 'Oudehaske', id: 129 } } },
  { email: '5ad114bf21843b91c0663cc73f0f8c93fe813e4d358acfdedf2fa6136222d505', filters: { 129: { title: 'Oudehaske', id: 129 } } },
  { email: '5256e84cf2e31390109e515c87b3968093767904a83f3a3b75810ce2fc40d7da', filters: { 129: { title: 'Oudehaske', id: 129 } } },

  { email: 'aac480e20651cb02c09b5f3d121197b5b3a650138857cd08fc8da84a809d1e9d', filters: { 129: { title: 'Purmerend', id: 129 } } },
  { email: 'c3c65d0887b3bb06ae9ccdec25c204d6947e24477f98a8e7487cb06cb84d6503', filters: { 129: { title: 'Purmerend', id: 129 } } },
  { email: '276bfa82a41c834fb2884a588ed8b152738d9afd108bd490b108cb2efa8a24f4', filters: { 129: { title: 'Purmerend', id: 129 } } },

  { email: '149919a7b1a240929a34764406715470f1bfc4eb2d5a141f7a991363884adc1e', filters: { 129: { title: 'Roosendaal', id: 129 } } },
  { email: '0994f79b4da1535780c0fadc58f276eb73a97732e54aab558376229ac0547cfe', filters: { 129: { title: 'Roosendaal', id: 129 } } },
  { email: 'd5fdc40e95c2d52a8f05942891aa34ecbc13b8c247b952db5a7e9d0e92696486', filters: { 129: { title: 'Roosendaal', id: 129 } } },
  { email: '5986a9b008c0bb5638babec7b1bea3fde81a98fc12ce3582ac0cc29236ad8562', filters: { 129: { title: 'Roosendaal', id: 129 } } },

  { email: '8ed085c782321a1eac75647b8f9a4d3dfd2dd96880220dba5167e65c5e70dfcd', filters: { 129: { title: 'Rotterdam', id: 129 } } },
  { email: 'fb5483c7ca8f36e22a337aab5145143ca10d7924c83212c9a6b9c2cd5b2f987a', filters: { 129: { title: 'Rotterdam', id: 129 } } },
  { email: '60d9fd45a66f810f4cf267683bc442fdcefd6d40cf6f37240a7b0f8c2b7ba232', filters: { 129: { title: 'Rotterdam', id: 129 } } },
  { email: 'c3f0f40c547ed958683c8ae373c19ad0dd661fafe94584543a147774f5df6a6f', filters: { 129: { title: 'Rotterdam', id: 129 } } },
  { email: 'fb0548424c29acbbf5b907129b09414de59e7647d99b23672acc7da6914e98c5', filters: { 129: { title: 'Rotterdam', id: 129 } } },
  { email: 'a33c3cfacf8f3b112fa70f526626e54e0aac10e9bf28f25efcdea993ea982ac2', filters: { 129: { title: 'Rotterdam', id: 129 } } },

  // { email: '558b12d2d277af05eb389e7d44f83557f1b5618b254910e5a710c6d005ca8757', filters: { 129: { title: 'Service Center', id: 129 } } },
  // { email: '84df262c492e669811d761e111707f813a219036c701983d86f7aea315a57f74', filters: { 129: { title: 'Service Center', id: 129 } } },
  // { email: 'c38f2dae8c4d3a861c3d2a93e012ceca382ae203b2fdb1e5522b2fe380e4e44d', filters: { 129: { title: 'Service Center', id: 129 } } },

  { email: '556cefd59048e766d92aa8aa628c671d4e744d510546b10cd0793e6fd9cf6fa3', filters: { 129: { title: 'Sittard', id: 129 } } },
  { email: '2bf0b0ddb284bc30c1e0d7561fe621ef85593e36104a4576cd743c3f4299b6ab', filters: { 129: { title: 'Sittard', id: 129 } } },

  { email: 'ce60fb359004c498369e01fee2b3c25794854998078d5fd533cfa34287555d28', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: '07c12091147eb30b5a59fc787cd7a945fd173a6589052b6ff5335fc58a0470c6', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: '3ae599072bcae34fb3f80f6882315c4e155ba883b035540c1eafad4d1cdf9947', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: '2e852ceaf029e2cce260a00ddb15ed8232cb390e025cb3b8da7a2d36dac840de', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: 'ad5126c088e9daf8fa0fbde6bb1b5b686a082e1778fffe3b93a5338a5018ba9b', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: 'a506d9ac6b2cf027ddb691371b3deb441ed0980de444b6c8d5ec995a235d7a67', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: 'e79672073b1be75fd35fe83fa9794c4bb5857c0f38252e93b59e79bb600dd9e2', filters: { 129: { title: 'Tiel', id: 129 } } },
  { email: '290b84642edf455784f32896196f64aab986f0687fee10f3963a36f896977056', filters: { 129: { title: 'Tiel', id: 129 } } },

  { email: 'e47024d015860c6bb2f357109eaba0f769c7cd39326c0c472802747caa6b108a', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '6ece95c3754546af93f687a86f17638ab9cf088a38292d74d2ca93774dbae80a', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '54c808a3673052d1f2d9f5fb3a22ff1b4c0872b33d6ec6a4a0f900b643e61b7e', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '11a3260b783d2abb60b14ae04c04241fe8dea41ee514145c402528c5606a7971', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '638945ec4b4ce31ecece848bbd7bee75271a55e908a8b4e403135e8ccbb52fa0', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: 'a8d061728e32e9e46ebe5e5642c9155daf81db260a50c7f040b5bc86fefa3d72', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '73f13b9882b2fbdd9bc4bbd6f0fd28748757dc36b1af6c90a66a865a0296e448', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '01610faa1e5b500e0c2fd0899e9e4855fef96113e8c8793563fd599ed47f93a8', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '1c3c80e53e7e62d0857bd51dd39d7977e1ed5f33ff5840a38fb7971536dfbed9', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '7a41425704d0682985aa50adaaf558c9f4a2bc6749ebd5799cd4af2d3823e6f4', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: '63afc84eda2b66ffaca4616d0c6a7fb49bbae4cdaeb1c01f62d62688a5f33ec2', filters: { 129: { title: 'Tilburg', id: 129 } } },
  { email: 'af93b3ef48de11dc9370d6e882b5b7226f29e67f9ef48985fbf90080d710db0f', filters: { 129: { title: 'Tilburg', id: 129 } } },

  { email: 'cc05912c0a98f88e94f116cda2d609f1a6e1ab520a92e4a0ba36614e03f46185', filters: { 129: { title: 'Uden', id: 129 } } },

  { email: '1ae0cd6de19281908d97972c32aa53490dfe08030300d079d34b9ddaf4966bc5', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: 'b0306e26ae7938873bbaaa5697a248461c211d13535ea6e3a5dc29c2a1cb2bca', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: '8db99a423d5e5622af18c5f76312e19604e771938d3d1e270e7642369c339e75', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: 'cba16648716f3e48eca30e515555b17b5ec3b8d28dd071e896dc1d51436785af', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: 'bb857085b2bdd70151e4ff63db6300dca16e4d14bcf617f2ccd11e1f89cadc3f', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: '9d007f40eb20c667570419a6c175216538b2468096c1db3d54580bdd2c033224', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: '9c1d407fe3ebe67c397d7c32e09b34fe068b1168cb6230b0360dc190e59f59a0', filters: { 129: { title: 'Venlo', id: 129 } } },
  { email: '8f5c283d0bdaf3f994992743a80f9eb9cf617357f27d73bf9f998c6d4ab320c4', filters: { 129: { title: 'Venlo', id: 129 } } },

  { email: '5fc4d046b6ebc25afae6bd5c3fb8baec93000e1f401ef89f95397b0754d5e6e7', filters: { 129: { title: 'Waalwijk', id: 129 } } },
  { email: 'c040583c0a0f457544140ac73714750e36246adc364bc545c891b8eb16d02c62', filters: { 129: { title: 'Waalwijk', id: 129 } } },
];

export const getDefaultWorkerFilters = (state) => {
  if (!config.isLogisticForce) {
    return {};
  }

  const manager = managersLFWithFilters.find((item) => item.email === state.auth.current.email);
  if (!manager) {
    return {};
  }

  return manager.filters;
};
